.star {
    width: 150px;
    height: auto;
}

.content-avis {
    width: 400px;
}

.glimmer-blue{
    padding: 1rem;
    z-index: -1;
    width: 100px;
    height: 400px;
    right: 1rem;
    position: absolute;
    background-color: rgb(39, 237, 255);
    filter: blur(80px); /* Ajoute l'effet de flou */

}

.glimmer-orange{
    padding: 1rem;
    z-index: -1;
    width: 100px;
    height: 300px;
    width: 200px;
    position: absolute;
    background-color: #FFA117;
    filter: blur(120px); /* Ajoute l'effet de flou */
}

.avis {
    background-color: white;
    border-radius: 18px;
    padding: 2rem;
    box-shadow: 1px 5px 5px 1px rgb(95, 95, 95);
    min-height: 330px;
}


@media only screen and (max-width: 1080px) {
    .avis-responsive {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .content-center {
        margin-left: 2rem;
        margin-right: 2rem;
        padding: 0;
    }

    .glimmer-orange{
        display: none;
    }
    .glimmer-blue{
        display: none;
    }

    .avis {
        padding: 1rem;
        padding-right: 0;
    }
}