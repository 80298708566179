.logo{
    margin-left: 2rem;
    margin-top: 1rem;
}

.body-header{
  background-color: #0D081C;
}

.background-glimmer {
    position: relative;
  }
  
  .background-glimmer::before,
  .background-glimmer::after {
    content: '';
    position: absolute;
    top: 25%; /* Ajustez cette valeur pour centrer verticalement la lueur */
    bottom: 25%; /* Ajustez cette valeur pour centrer verticalement la lueur */
    width: 50%;
    z-index: -1;
  }
  
  .background-glimmer::before {
    left: 0;
    background: linear-gradient(to right, #FFA117, white 30%, transparent 50%);
  }
  
  .background-glimmer::after {
    right: 0;
    top: 0;
    height: 50%;
    border-radius: 50%;
    background: linear-gradient(to left, #36F0FC, white 50%, transparent 10%);
  }

  .glimmer-orange-top{
    padding: 1rem;
    z-index: 1;
    left: 1px;
    width: 100px;
    height: 300px;
    width: 200px;
    position: absolute;
    background-color: #FFA117;
    filter: blur(120px); /* Ajoute l'effet de flou */
}

.glimmer-blue-top{
  padding: 1rem;
  z-index: 1;
  right: 1px;
  width: 100px;
  height: 300px;
  width: 200px;
  position: absolute;
  background-color: #45daff;
  filter: blur(120px); /* Ajoute l'effet de flou */
}

  @media only screen and (max-width: 767px) {
    .logo{
      width: 110%;
      margin-left: 10px;
    }
    .glimmer-blue-top{
      display: none;
    }

    .glimmer-orange-top{
      display: none;
    }

    .mobile-center{
      justify-content: right;
      margin: 0 !important;
    }

    .words{
      gap: 0 !important;
      font-size: 13px;
    }
}
  
  
  