.title-header {
    font-size: 3.5vw;
    text-align: center;
    font-weight: 600;
    color: white;
}

.text-header {
    font-size: 1.2vw;
    text-align: center;
    font-weight: 500;
    color: white;
}

.stats-img {
    border: 2px solid rgb(152, 152, 152);
    border-radius: 30px 30px 0px 0px;
    border-bottom: none;
}

.fade-background {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(30deg, #30E9B6, #3364FF);
    background-image: linear-gradient(30deg, #30E9B6, #3364FF);
}

.input-top {
    font-size: 15px;
    position: relative;
    left: 3rem;
    width: 67%;
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #B4B2B9;
    background-color: transparent;
    color: #99979d;
    /* Couleur du texte */
}

.words {
    color: white;
}

.btn-freeTrail {
    position: relative;
    height: 40px;
    top: 8px;
    padding-top: 0;
    padding-bottom: 0;
    right: 7rem;
}

.div-customers{
    background-color: white;
    width: 300px;
}

.filter-grey {
    filter: grayscale(100%);
}

.alc {
    width: 100px;

}

.ratp {
    width: 250px;
}

.esc {
    width: 110px;
}

.factory {
    width: 110px;
}

.img-customer {
    width: 100px;
}


/* customers */

.fadeEffect {
    animation: fadeInOut 2s linear;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



@media only screen and (max-width: 1490px) {
    .no-mg {
        margin-right: 2rem !important;
    }
}

@media only screen and (max-width: 1000px) {
    .stats-img{
        display: none !important;
    }
}

@media only screen and (max-width: 951px) {
    .alc {
        width: 100px !important;

    }

    .ratp {
        width: 160px !important;
    }

    .esc {
        width: 100px !important;
    }

    .btn-freeTrail {
        padding: 0;
        width: 25%;
    }
}

@media only screen and (max-width: 767px) {
    .no-mg {
        margin: 0 !important;
        width: auto;
    }

    .btn-freeTrail {
        padding: 0;
        width: 20%;
    }

    .img-customer {
        width: 130px !important;
    }

    .alc {
        width: 90px !important;

    }

    .esc {
        width: 80px !important;
    }

    .ratp {
        width: 150px !important;
    }

    .img-croix-rouge {
        width: 70px !important;
    }

    .customers-title {
        font-size: 3.5vw !important;
    }

    .img-procter {
        width: 90px !important;
    }

    .mobile-center {
        justify-content: center;
        width: 100%;
    }

    .responsive-width {
        width: 100% !important;
    }

    .font-responsive {
        font-size: 2.5vw !important;
    }

    .font-responsive-title {
        font-size: 6vw !important;
    }

    .btn-freeTrail {
        right: 5rem;
    }

    .gap-responsive {
        gap: 1rem;
    }
}

@media only screen and (max-width: 645px) {
    .btn-freeTrail {
        padding: 0;
        width: 33%;
    }

}