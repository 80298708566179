.border-left {
    border-radius: 18px 0px 0px 18px;
}

.border-right {
    border-radius: 0px 18px 18px 0px;
}

.pic-customer{
    width: 600px !important;
    height: auto;
}

.mg-r-l {
    margin-right: 18rem;
    margin-left: 18rem;
}

.transparent-input {
    font-size: 15px;
    padding: 25px;
    border-radius: 30px;
    border: 2px solid #B4B2B9;
    background-color: transparent;
    color: #B4B2B9;
    /* Couleur du texte */
}

.transparent-input::placeholder {
    padding-left: 1rem;
    color: #B4B2B9;
    /* Couleur du placeholder */
}

.transparent-input:focus::placeholder {
    color: transparent;
    /* Pour que le placeholder devienne transparent quand l'input est en focus */
}

.btn-bottom {
    position: relative;
    padding: 10px;
    height: 40px;
    top: 6px;
    width: 120px;
    font-size: 13px;
    left: -8rem;
    right: 1rem;
}

.btn-bottom-responsive {
    position: relative;
    padding: 10px;
    height: 40px;
    top: -5rem;
    width: 120px;
    font-size: 11px;
    left: 13rem;
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1320px) {
    .mg-r-l{
        margin: 0;
    }
}

@media only screen and (max-width: 1320px) {
    .btn-freeTrialBottom {
        left: 25rem;
    }

}

@media only screen and (max-width: 1250px) {
    .btn-freeTrialBottom {
        left: 20rem;
    }
}

@media only screen and (max-width: 950px) {
    .img-customer {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .border-left {
        border-radius: 18px 18px 0px 0px;
    }

    .content-block {
        padding: 0 !important;
    }

    .border-right {
        border-radius: 0px 0px 18px 18px;
    }

    .grid {
        flex-direction: column;
    }

    /* .btn-bottom {
        position: relative;
        padding: 10px;
        height: 40px;
        bottom: 15rem;
        top: -5rem;
        left: 14rem;
        width: 120px;
        font-size: 11px;
    } */

    .pic-customer {
        width: 100% !important;
    }

    .blackBlock {
        width: 100%;
    }

    .w-auto-responive {
        width: auto;
    }

    .bottom-checks {
        margin: 1rem !important;
        gap: 2rem !important;
    }

}