.footer {
    background-color: #0D081C;
    margin-top: 12%;
    width: auto;
}

.content-center2{
    margin-left: 1rem;
    margin-right: 1rem;
}

.info {
    padding: 0 auto;
    margin: 0 auto;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 767px) {
    .info{
        margin: 0 !important;
    }

    .grid-footer{
        justify-content: center;
        align-items: center;
    }

    .col-footer{
        width: auto;
    }
}

@media screen and (max-width: 576px) {
    .info {
        font-size: 15px;
        max-width: 20rem;
        margin-top: 4rem;
        margin-left: 0;
    }
}