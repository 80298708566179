.p-panel-header{
    background-color: white !important;
    border: none;
    font-size: 20px;
}

.p-panel-content{
    border: none;
    text-align: left;
    width: 900px;
}

.p-panel-toggler{
    border: 3px solid black;
    font-weight: 700;
    color: black;
}

.p-panel-toggler:hover{
    background-color: #00BD9D;
    border: none;
    color: white;
}

.faq-content{
    margin-right: 15rem;
    margin-left: 15rem;
}


@media only screen and (max-width: 1320px) {
    .faq-content{
        margin: 0;
    }
}
