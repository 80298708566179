.options-title {
  font-size: 42px;
  font-weight: 700;
}

.list-content{
  width: 550px;
}

.img-options{
  width: 300px;
  height: auto;
}

.div-text{
  position: relative;
  left: 5rem;
}

.div-width{
  width: 300px;
}

.customer-call{
  width: auto;
  height: 320px;
  position: relative;

}

.calls{
  position: relative;
  top: 1.5rem;
}

.peek-number{
  position: relative;
  top: 50px;
}

.number-peek-size{
  width: 330px !important;
}

.div-options{
  width: auto;
}

.config-div{
  width: 330px !important;

}

.content-options{
  margin-left: 5rem;
}

.clickable-div {
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 30px;
}

.img-container {
  width: 400px;
  height: auto; /* Réglez la hauteur selon vos besoins */
}


.clickable-div:hover {
  background-color: #0D081C;
  color: white;
}

.clickable-div {
  cursor: pointer;
}

.clicked {
  background-color: #0D081C;
  color: white;
}

.hovered {
  background-color: #0D081C;
  color: white;
}

.img-number {
  width: 100%;
}

.gap-options {
  gap: 20rem;
}

@media only screen and (max-width: 1400px) {
  .gap-options {
    gap: 9rem;
  }
}

@media only screen and (max-width: 700px) {

  .customer-call{
    width: auto;
    height: auto;
  }

  .div-text{
    left: 0;
  }
}