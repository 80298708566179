.bg-black {
    background-color: #0D081C;
}

.grey-bg {
    background-color: #313033;
    padding: 1rem;
    border-radius: 10px;
}

.font-25 {
    color: white;
    font-size: 30px;
    font-weight: 600;
}

.font-title {
    font-size: 40px;
    font-weight: 600;
}

.accueil {
    position: relative;
    right: 1rem;
}

@media only screen and (max-width: 920px) {
  
}


@media only screen and (max-width: 767px) {
    .w-50 {
        width: 100%;
    }

    .responsive-layout {
        justify-content: center !important;
        align-items: center !important;
    }

    .tel-cloud {
        text-align: left !important;
        margin-left: 1rem;
    }

    .tel-cloud {
        font-size: 30px !important;
    }

    .picture-customer {
        width: 80%;
        height: 120%;
    }

    .width-auto {
        width: auto !important;
        padding-right: 1rem !important;
    }

    .accueil {
        right: 0;
        padding: 1rem;
    }

    .no-p{
        padding: 0;
    }



    .blocks {
        padding-right: 1rem !important;
    }

    .customer-responsive {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}